var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{staticClass:"p-fluid",attrs:{"visible":_vm.visible,"header":_vm.exibirHeader(),"closeOnEscape":!_vm.saving && !_vm.confirmDialog,"closable":!_vm.saving,"modal":true,"containerStyle":{ width: '70vw' }},on:{"show":function($event){return _vm.onShow()},"hide":function($event){return _vm.onHide()},"update:visible":function($event){return _vm.fechar()}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{staticClass:"p-button-text",attrs:{"disabled":_vm.saving,"label":"Cancelar","icon":"pi pi-times"},on:{"click":function($event){return _vm.fechar()}}}),_c('Button',{attrs:{"disabled":_vm.saving,"autofocus":""},on:{"click":_vm.validate}},[(!_vm.saving)?_c('span',{staticClass:"pi pi-check"}):_vm._e(),(_vm.saving)?_c('span',{staticClass:"pi pi-spin pi-spinner"}):_vm._e(),(!_vm.saving)?_c('span',{staticClass:"ml-2"},[_vm._v("Salvar")]):_vm._e(),(_vm.saving)?_c('span',{staticClass:"ml-2"},[_vm._v("Aguarde")]):_vm._e()])]},proxy:true}])},[_c('BlockUI',{attrs:{"blocked":_vm.saving}},[_c('Card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"block"},[_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{attrs:{"for":"status"}},[_vm._v("Status")]),_c('Dropdown',{class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.consignacao.statusConsignacaoDto.$invalid,
              },attrs:{"id":"status","data-key":"id","options":_vm.listaStatusConsignacao,"placeholder":"Selecione um tipo"},scopedSlots:_vm._u([{key:"value",fn:function(slotProps){return [(slotProps)?_c('div',[_c('span',[_vm._v(" "+_vm._s(slotProps.value.nome)+" ")])]):_c('div',[_c('span',[_vm._v(_vm._s(slotProps.placeholder))])])]}},{key:"option",fn:function(slotProps){return [_c('div',[_c('span',[_vm._v(" "+_vm._s(slotProps.option.nome)+" ")])])]}}]),model:{value:(_vm.v$.consignacao.statusConsignacaoDto.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.statusConsignacaoDto, "$model", $$v)},expression:"v$.consignacao.statusConsignacaoDto.$model"}}),(
                _vm.submitted &&
                _vm.v$.consignacao.statusConsignacaoDto.required.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo tipo é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"rubrica"}},[_vm._v("Rubrica")]),_c('Dropdown',{class:{
                'p-invalid': _vm.submitted && _vm.v$.consignacao.rubrica.$invalid,
              },attrs:{"id":"rubrica","data-key":"id","disabled":_vm.desativarCampo(),"options":_vm.rubricas,"placeholder":"Selecione uma rubrica"},scopedSlots:_vm._u([{key:"value",fn:function(slotProps){return [(slotProps.value.rubrica)?_c('div',[_c('span',[_vm._v(" "+_vm._s(slotProps.value.rubrica)+" | "+_vm._s(slotProps.value.nome)+" | "+_vm._s(slotProps.value.atividade.nome)+" ")])]):_c('div',[_c('span',[_vm._v(_vm._s(slotProps.placeholder))])])]}},{key:"option",fn:function(slotProps){return [_c('div',[_c('span',[_vm._v(" "+_vm._s(slotProps.option.rubrica)+" | "+_vm._s(slotProps.option.nome)+" | "+_vm._s(slotProps.option.atividade.nome)+" ")])])]}}]),model:{value:(_vm.v$.consignacao.rubrica.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.rubrica, "$model", $$v)},expression:"v$.consignacao.rubrica.$model"}}),(_vm.submitted && _vm.v$.consignacao.rubrica.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo rubrica é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"numeroContrato"}},[_vm._v("Nº Contrato")]),_c('InputText',{class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.consignacao.numeroContrato.$invalid,
              },attrs:{"id":"numeroContrato","type":"text","disabled":_vm.desativarCampo(),"placeholder":"Digite o número contrato"},model:{value:(_vm.v$.consignacao.numeroContrato.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.numeroContrato, "$model", $$v)},expression:"v$.consignacao.numeroContrato.$model"}})],1),_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"valorParcela"}},[_vm._v("Valor Parcela")]),_c('div',{staticClass:"p-inputgroup"},[_c('span',{staticClass:"p-inputgroup-addon"},[_vm._v("R$")]),_c('InputNumber',{class:{
                  'p-invalid':
                    _vm.submitted && _vm.v$.consignacao.valorParcela.$invalid,
                },attrs:{"id":"valorParcela","mode":"decimal","locale":"pt-BR","minFractionDigits":2,"maxFractionDigits":5,"disabled":_vm.desativarCampo(),"placeholder":"0,00"},model:{value:(_vm.v$.consignacao.valorParcela.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.valorParcela, "$model", $$v)},expression:"v$.consignacao.valorParcela.$model"}})],1),(
                _vm.submitted && _vm.v$.consignacao.valorParcela.required.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo valor parcela deve conter um número superior ou igual a 1. ")]):_vm._e()]),_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{attrs:{"for":"anoReferencia"}},[_vm._v("Ano")]),_c('InputMask',{class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.consignacao.anoReferencia.$invalid,
              },attrs:{"id":"anoReferencia","disabled":_vm.desativarCampo(),"mask":"9999"},model:{value:(_vm.v$.consignacao.anoReferencia.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.anoReferencia, "$model", $$v)},expression:"v$.consignacao.anoReferencia.$model"}}),(
                _vm.submitted && _vm.v$.consignacao.anoReferencia.required.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo ano é obrigatório ")]):_vm._e()],1),_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{attrs:{"for":"mesReferencia"}},[_vm._v("Mês (1 até 12)")]),_c('InputNumber',{class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.consignacao.mesReferencia.$invalid,
              },attrs:{"id":"mesReferencia","disabled":_vm.desativarCampo(),"min":1,"max":12},model:{value:(_vm.v$.consignacao.mesReferencia.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.mesReferencia, "$model", $$v)},expression:"v$.consignacao.mesReferencia.$model"}}),(
                _vm.submitted && _vm.v$.consignacao.mesReferencia.required.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo mês é obrigatório ")]):_vm._e(),(
                _vm.submitted &&
                _vm.v$.consignacao.mesReferencia.numeroPermitido.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo mês deve ser entre 01 e 12 ")]):_vm._e()],1),(_vm.exibirCampo())?_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"prazoRestante"}},[_vm._v("Prazo Restante")]),_c('InputNumber',{class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.consignacao.prazoRestante.$invalid,
              },attrs:{"id":"prazoRestante","min":0,"max":999},model:{value:(_vm.v$.consignacao.prazoRestante.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.prazoRestante, "$model", $$v)},expression:"v$.consignacao.prazoRestante.$model"}}),(
                _vm.submitted && _vm.v$.consignacao.prazoRestante.required.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo prazo total é obrigatório. ")]):_vm._e(),(
                _vm.submitted && _vm.v$.consignacao.prazoRestante.maxValue.$invalid
              )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo prazo restante não pode ser maior do que o prazo total ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"prazoTotal"}},[_vm._v("Prazo Total")]),_c('InputNumber',{class:{
                'p-invalid': _vm.submitted && _vm.v$.consignacao.prazoTotal.$invalid,
              },attrs:{"id":"prazoTotal","min":0,"max":999},model:{value:(_vm.v$.consignacao.prazoTotal.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.prazoTotal, "$model", $$v)},expression:"v$.consignacao.prazoTotal.$model"}}),(_vm.submitted && _vm.v$.consignacao.prazoTotal.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo prazo total é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"inline-block col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"carencia"}},[_vm._v("Carência")]),_c('InputNumber',{class:{
                'p-invalid': _vm.submitted && _vm.v$.consignacao.carencia.$invalid,
              },attrs:{"id":"carencia","disabled":_vm.desativarCampo(),"min":0,"max":6,"placeholder":"Informar a carência em meses (Entre 0 e 6)"},model:{value:(_vm.v$.consignacao.carencia.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.carencia, "$model", $$v)},expression:"v$.consignacao.carencia.$model"}}),(_vm.submitted && _vm.v$.consignacao.carencia.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo carência é obrigatório ")]):_vm._e()],1),_c('div',{staticClass:"p-fluid formgrid grid col-12"},[_c('div',{staticClass:"field md:col-12"},[_c('label',{staticClass:"required",attrs:{"for":"observacao"}},[_vm._v("Observação")]),_c('Textarea',{class:{
                  'p-invalid':
                    _vm.submitted && _vm.v$.consignacao.observacoes.$invalid,
                },attrs:{"id":"observacao","autoResize":true},model:{value:(_vm.v$.consignacao.observacoes.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.observacoes, "$model", $$v)},expression:"v$.consignacao.observacoes.$model"}}),_vm._v(" "),(
                  _vm.submitted && _vm.v$.consignacao.observacoes.required.$invalid
                )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Observação é obrigatório ")]):_vm._e()],1)])])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }